import React, { useState, useEffect } from 'react';
import _ from "lodash";
import { TextField, MenuItem, LinearProgress, Grid, Card, CardContent, Chip, Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import appVars from '../../../appVars.json';
import Logo from "../../../images/StackAdapts-New-Logo.png";
import { Autocomplete } from '@material-ui/lab';







const useStyles = makeStyles((theme) => ({
    formControl: {
        marginBottom: theme.spacing(1),
    },
    chip: {
        margin: 2,
        overflow: 'hidden'
    },
}));



const StackAdaptTokens = (props) => {
   // console.log(props);
    const [apiKeys, setApiKeys] = useState();
    const [api, setApi] = useState(props.saKey);
    const classes = useStyles();
    const [selectedOptions, setSelectedOptions] = useState(() => {
        // Check if saKey is a string and contains commas (CSV string)
        if (typeof props.saKey === 'string' && props.saKey.includes(',')) {
            return props.saKey.split(',').map(value => value.trim());
        }
        // Check if saKey is defined and not an empty string, then wrap in an array
        // This will handle single numbers, single strings without commas, and non-empty strings
        else if (props.saKey || typeof props.saKey === 'string') {
            return [`${props.saKey}`]; // Convert to string to ensure consistency
        }
        // Return an empty array if saKey is undefined, null, or empty
        return [];
    });


    const [selectedOptions2, setSelectedOptions2] = useState(() => {
        // Check if defaultValue is a string and contains commas (CSV string)
        if (typeof props.defaultValue === 'string' && props.defaultValue.includes(',')) {
            return props.defaultValue.split(',').map(value => value.trim());
        }
        // Check if defaultValue is defined and not an empty string, then wrap in an array
        // This will handle single numbers, single strings without commas, and non-empty strings
        else if (props.defaultValue || typeof props.defaultValue === 'number') {
            return [`${props.defaultValue}`]; // Convert to string to ensure consistency
        }
        // Return an empty array if defaultValue is undefined, null, or empty
        return [];
    });


    const [options, setOptions] = useState();
    const [token, setToken] = useState(props.token);


    async function fetchData() {
        var url = appVars.api_url + "/stackadapt/api/tokens";
        const res2 = await fetch(url);
        res2
            .json()
            .then(async (res2) => {
                setApiKeys(res2);
                //   console.log(apiKeys);

                if (selectedOptions[0] !== '') {


                    let type = '';
                    let url = appVars.api_url;
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        redirect: 'follow',
                        body: JSON.stringify({ token: selectedOptions[0] })
                    };


                    url = appVars.api_url + "/stackadapt/api/ga";
                    const response = await fetch(url, requestOptions);

                    const data = await response.json();
                    const filteredData = type ? _.filter(data, (row) => _.includes(row.ACCOUNT_NAME, type)) : data;


                    setOptions(filteredData);

                }




            })
            .catch((err) => {
                //setErrors({ status: true, msg: err.message });
                //console.log(err);
            });
    }


    useEffect(() => {
        fetchData();
        return () => {

        };
    }, []);




    return (

        <div className={classes.formControl}>

            <Card >

                <CardContent>

                    <Grid container spacing={2}>
                        {(apiKeys ?


                            <Grid item sm={12}>
                                <Autocomplete
                                    multiple
                                    id="tags-standard"
                                    disabled={(props.admintoken >= 6 ? false : true)}
                                    options={apiKeys}
                                    getOptionLabel={(option) => `${option.name} - ${option.token}`}
                                    defaultValue={selectedOptions.map((option) => {
                                        //  console.log(option)
                                        return (
                                            apiKeys.find((o) => {
                                                // console.log(o)
                                                return (o.token.toString() === option || {})
                                            })
                                        )
                                    })
                                    }
                                    data-tab={props.tab}
                                    onChange={async (e, newValue) => {
                                        
                                        
                                        if (newValue.length>=1) {
                                            
                                        
                                        
                                        console.log(newValue[1].token);
                                        setToken(newValue[1].token);




                                        let type = '';
                                        let url = appVars.api_url;
                                        const requestOptions = {
                                            method: 'POST',
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json'
                                            },
                                            redirect: 'follow',
                                            body: JSON.stringify({ token: newValue[0].token })
                                        };


                                        url = appVars.api_url + "/stackadapt/api/ga";
                                        const response = await fetch(url, requestOptions);

                                        const data = await response.json();
                                        const filteredData = type ? _.filter(data, (row) => _.includes(row.ACCOUNT_NAME, type)) : data;


                                        setOptions(filteredData);
                                    }

                                    }}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip data-tab={props.tab} label={option.name} {...getTagProps({ index })} className={classes.chip} style={{ backgroundColor: props.themeColor.secondary, color: props.themeColor.primary }} key={option.token} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            data-tab={props.tab}
                                            {...params}
                                            variant="outlined"
                                            label={`Select ${props.label} Customer Account`}

                                        />
                                    )}
                                />   </Grid>
                            : <LinearProgress></LinearProgress>
                        )}

                        <Grid item sm={12}>
                            {(options ?
                                <Autocomplete
                                    multiple
                                    id="tags-standard"
                                    disabled={(props.admintoken >= 6 ? false : true)}
                                    options={options}
                                    getOptionLabel={(option) => `${option.ACCOUNT_NAME} - ${option.ACCOUNT_ID}`}
                                    defaultValue={selectedOptions2.map((option) => options.find((o) => o.ACCOUNT_ID.toString() === option) || {})}
                                    data-tab={props.tab}
                                    onChange={(e, newValue) => {
                                        console.log(newValue);


                                        if (newValue.length >= 1) {
                                            const reportReturn = Object.keys(newValue).map((key, index) => (
                                                newValue[index].ACCOUNT_ID
                                            ));
                                            e.stopPropagation();
                                            setSelectedOptions(reportReturn);
                                            if (props.setSelectedApiKey) {
                                                props.setSelectedApiKey(reportReturn, props.tab, props.label, selectedOptions[0]);
                                            }
                                            //console.log(props.tab);
                                        }else{
                                            props.setSelectedApiKey(0, props.tab, props.label, selectedOptions[0]);

                                        }
                                    }}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip data-tab={props.tab} label={option.ACCOUNT_NAME} {...getTagProps({ index })} className={classes.chip} style={{ backgroundColor: props.themeColor.secondary, color: props.themeColor.primary }} key={option.ACCOUNT_ID} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            data-tab={props.tab}
                                            {...params}
                                            variant="outlined"
                                            label={`Select ${props.label}`}

                                        />
                                    )}
                                />
                                : <Typography>Select Customer Account</Typography>
                            )}
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
        </div>

    );
};

StackAdaptTokens.propTypes = {

};

export default StackAdaptTokens;
