import React from 'react';
import { Link, Typography, Card, CardContent, useTheme, CardHeader } from '@material-ui/core';



const ContactRep = (props) => {

    const theme = useTheme();

    //console.log(props);
    return (
        <div style={{ maxWidth: 1200, margin: 'auto' }} >
            <Card>
                <CardContent>
                    <Typography align='center' style={{padding:theme.spacing(3)}}>
                        {props.client.name} <br/> is in-active please <br/>Contact {props.client.clientname}
                    </Typography>
                </CardContent>
            </Card>



        </div>
    );
};

ContactRep.propTypes = {

};

export default ContactRep;
